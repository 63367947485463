import {
  Alert,
  Button,
  Card,
  Col,
  Row,
  Space,
  Spin,
  Switch,
  Table,
  Tabs,
} from "antd";
import { ColumnGroupType } from "antd/lib/table";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { TSummaryReportAggregatedItem } from "@/api";
import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";
import CurrencySelector from "@/ui/_common_/currency-selector";
import DateRangePicker from "@/ui/_common_/date-range-picker";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import Page from "@/ui/_common_/page";
import Pagination from "@/ui/_common_/pagination";
import Selector from "@/ui/_common_/selector";

import {
  SummaryReportState,
  TItemAggregatedByCurrency,
  TItemAggregatedByHall,
} from "./SummaryReportState";

function SummaryReport() {
  const globalStore = useGlobalStore();
  const state = useViewModel(() => new SummaryReportState(globalStore), {});
  const intl = useIntl();
  const { permissionsStore } = globalStore;
  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: "Summary report",
      })}
    >
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {permissionsStore.has("SelectClient") && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <Selector
                store={state.clientSelectorStore}
                placeholder={intl.formatMessage({
                  defaultMessage: "Select client",
                })}
              />
            </Col>
          )}
          {!!state.clientSelectorStore.selectedId && (
            <>
              {permissionsStore.has("SelectAgent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.agentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select an agent",
                    })}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectSubagent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.subagentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select subagent",
                    })}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectHall") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.hallSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select shop",
                    })}
                  />
                </Col>
              )}
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={state.playerSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select player",
                  })}
                />
              </Col>
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={state.kioskSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select kiosk",
                  })}
                />
              </Col>
            </>
          )}
        </Row>
      </Card>
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {!state.userStore.hall && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <CurrencySelector
                value={state.currency}
                onChange={state.setCurrency}
                style={{ width: "100%" }}
              />
            </Col>
          )}
          <Col>
            <DateRangePicker store={state} />
          </Col>
          <Col xs={24} md={8} lg={6} xl={4}>
            <Row gutter={[8, 8]} align="middle" style={{ height: "100%" }}>
              <Col>{intl.formatMessage({ defaultMessage: "Compact" })}</Col>
              <Col>
                <Switch
                  checked={state.isCompactModeEnabled}
                  onChange={(value) => (state.isCompactModeEnabled = value)}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} lg={6} xl={3} style={{ marginLeft: "auto" }}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              disabled={!state.clientSelectorStore.selectedId}
              onClick={state.apply}
            >
              {intl.formatMessage({ defaultMessage: "Apply" })}
            </Button>
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Spin
          spinning={
            state.aggregateByCurrencyQuery.isPending ||
            state.aggregateByHallQuery.isPending
          }
        >
          <Space direction="vertical">
            <ErrorsFormatter
              queries={[
                state.aggregateByCurrencyQuery,
                state.aggregateByHallQuery,
              ]}
            />
            <Tabs
              destroyInactiveTabPane={true}
              activeKey={state.tab}
              onChange={(value) => (state.tab = value)}
            >
              <Tabs.TabPane
                tab={intl.formatMessage({
                  defaultMessage: "Net profit",
                })}
                key="netProfit"
              >
                <Table
                  dataSource={state.itemsAggregatedByCurrency}
                  showHeader={!!state.itemsAggregatedByCurrency.length}
                  rowKey={(item) =>
                    state.itemsAggregatedByCurrency.indexOf(item)
                  }
                  size="small"
                  bordered
                  pagination={false}
                  columns={[
                    {
                      title: intl.formatMessage({
                        defaultMessage: "Currency",
                      }),
                      dataIndex: "currency",
                      align: "right",
                      render: (children, { rowSpan }) => ({
                        children,
                        props: { rowSpan },
                      }),
                    },
                    {
                      title: intl.formatMessage({
                        defaultMessage: "Games",
                      }),
                      align: "center",
                      children: [
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Provider",
                          }),
                          align: "left",
                          render: (item: TSummaryReportAggregatedItem) => {
                            if (item.games.length) {
                              return item.games[0].providerName;
                            }
                            return {
                              children: <Row justify="center">—</Row>,
                              props: {
                                colSpan: 2,
                              },
                            };
                          },
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Profit",
                          }),
                          align: "right",
                          render: (item: TSummaryReportAggregatedItem) => {
                            if (item.games.length) {
                              return (
                                <MoneyFormatter cents={item.games[0].profit} />
                              );
                            }
                            return { props: { colSpan: 0 } };
                          },
                        },
                      ],
                    },
                    {
                      title: intl.formatMessage({
                        defaultMessage: "Finance",
                      }),
                      align: "center",
                      children: [
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Balance revenue",
                          }),
                          align: "right",
                          dataIndex: ["finances", "balanceRevenue"],
                          render: (value, { rowSpan }) => ({
                            children: <MoneyFormatter cents={value} />,
                            props: { rowSpan },
                          }),
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Bonus revenue",
                          }),
                          align: "right",
                          dataIndex: ["finances", "bonusRevenue"],
                          render: (value, { rowSpan }) => ({
                            children: <MoneyFormatter cents={value} />,
                            props: { rowSpan },
                          }),
                        },
                        {
                          title: intl.formatMessage({
                            defaultMessage: "Cash revenue",
                          }),
                          align: "right",
                          dataIndex: ["finances", "cashRevenue"],
                          render: (value, { rowSpan }) => ({
                            children: <MoneyFormatter cents={value} />,
                            props: { rowSpan },
                          }),
                        },
                      ],
                    },
                    {
                      title: intl.formatMessage({
                        defaultMessage: "Jackpots",
                      }),
                      align: "center",
                      dataIndex: ["jackpots", "paid"],
                      render: (value, { rowSpan }) => ({
                        children: (
                          <div style={{ textAlign: "right" }}>
                            <MoneyFormatter cents={value} />
                          </div>
                        ),
                        props: { rowSpan },
                      }),
                    },
                    {
                      title: intl.formatMessage({
                        defaultMessage: "Fortune Wheels",
                      }),
                      align: "center",
                      dataIndex: ["fortuneWheels", "paid"],
                      render: (value, { rowSpan }) => ({
                        children: (
                          <div style={{ textAlign: "right" }}>
                            <MoneyFormatter cents={value} />
                          </div>
                        ),
                        props: { rowSpan },
                      }),
                    },
                    {
                      title: intl.formatMessage({
                        defaultMessage: "Net profit",
                      }),
                      align: "right",
                      render: (item: TSummaryReportAggregatedItem) => {
                        if (item.games.length) {
                          return (
                            <MoneyFormatter cents={item.games[0].netProfit} />
                          );
                        }
                        return "—";
                      },
                    },
                  ]}
                />
                <Pagination
                  query={state.aggregateByCurrencyQuery}
                  onChange={() => {
                    state.aggregateByCurrency({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={intl.formatMessage({
                  defaultMessage: "Currency",
                })}
                key="currency"
              >
                <Table
                  dataSource={state.itemsAggregatedByCurrency}
                  showHeader={!!state.itemsAggregatedByCurrency.length}
                  rowKey={(item) =>
                    state.itemsAggregatedByCurrency.indexOf(item)
                  }
                  size="small"
                  bordered
                  pagination={false}
                  columns={[
                    {
                      title: intl.formatMessage({
                        defaultMessage: "Currency",
                      }),
                      dataIndex: "currency",
                      align: "right",
                      render: (children, { rowSpan }) => ({
                        children,
                        props: { rowSpan },
                      }),
                    },
                    {
                      title: intl.formatMessage({
                        defaultMessage: "Games",
                      }),
                      align: "center",
                      children: (
                        [
                          {
                            title: intl.formatMessage({
                              defaultMessage: "Provider",
                            }),
                            align: "left",
                            render: (item) => {
                              if (item.games.length) {
                                return item.games[0].providerName;
                              }
                              return {
                                children: <Row justify="center">—</Row>,
                                props: {
                                  colSpan: state.isCompactModeEnabled ? 2 : 4,
                                },
                              };
                            },
                          },
                          !state.isCompactModeEnabled && {
                            title: intl.formatMessage({
                              defaultMessage: "Bet",
                            }),
                            align: "right",
                            render: (item) => {
                              if (item.games.length) {
                                return (
                                  <MoneyFormatter cents={item.games[0].bet} />
                                );
                              }
                              return { props: { colSpan: 0 } };
                            },
                          },
                          !state.isCompactModeEnabled && {
                            title: intl.formatMessage({
                              defaultMessage: "Win",
                            }),
                            align: "right",
                            render: (item) => {
                              if (item.games.length) {
                                return (
                                  <MoneyFormatter cents={item.games[0].win} />
                                );
                              }
                              return { props: { colSpan: 0 } };
                            },
                          },
                          {
                            title: intl.formatMessage({
                              defaultMessage: "Profit",
                            }),
                            align: "right",
                            render: (item) => {
                              if (item.games.length) {
                                return (
                                  <MoneyFormatter
                                    cents={item.games[0].profit}
                                  />
                                );
                              }
                              return { props: { colSpan: 0 } };
                            },
                          },
                        ] as ColumnGroupType<TItemAggregatedByCurrency>["children"]
                      ).filter(Boolean),
                    },
                    {
                      title: intl.formatMessage({
                        defaultMessage: "Finance",
                      }),
                      align: "center",
                      children: (
                        [
                          {
                            title: intl.formatMessage({
                              defaultMessage: "Deposit",
                            }),
                            align: "center",
                            children: (
                              [
                                !state.isCompactModeEnabled && {
                                  title: intl.formatMessage({
                                    defaultMessage: "In",
                                  }),
                                  align: "right",
                                  dataIndex: ["finances", "balanceIn"],
                                  render: (value, { rowSpan }) => ({
                                    children: <MoneyFormatter cents={value} />,
                                    props: { rowSpan },
                                  }),
                                },
                                !state.isCompactModeEnabled && {
                                  title: intl.formatMessage({
                                    defaultMessage: "Out",
                                  }),
                                  align: "right",
                                  dataIndex: ["finances", "balanceOut"],
                                  render: (value, { rowSpan }) => ({
                                    children: <MoneyFormatter cents={value} />,
                                    props: { rowSpan },
                                  }),
                                },
                                {
                                  title: intl.formatMessage({
                                    defaultMessage: "Δ",
                                  }),
                                  align: "right",
                                  dataIndex: ["finances", "balanceRevenue"],
                                  render: (value, { rowSpan }) => ({
                                    children: <MoneyFormatter cents={value} />,
                                    props: { rowSpan },
                                  }),
                                },
                              ] as ColumnGroupType<TItemAggregatedByCurrency>["children"]
                            ).filter(Boolean),
                          },
                          {
                            title: intl.formatMessage({
                              defaultMessage: "Bonus",
                            }),
                            align: "center",
                            children: (
                              [
                                !state.isCompactModeEnabled && {
                                  title: intl.formatMessage({
                                    defaultMessage: "In",
                                  }),
                                  align: "right",
                                  dataIndex: ["finances", "bonusIn"],
                                  render: (value, { rowSpan }) => ({
                                    children: <MoneyFormatter cents={value} />,
                                    props: { rowSpan },
                                  }),
                                },
                                !state.isCompactModeEnabled && {
                                  title: intl.formatMessage({
                                    defaultMessage: "Out",
                                  }),
                                  align: "right",
                                  dataIndex: ["finances", "bonusOut"],
                                  render: (value, { rowSpan }) => ({
                                    children: <MoneyFormatter cents={value} />,
                                    props: { rowSpan },
                                  }),
                                },
                                {
                                  title: intl.formatMessage({
                                    defaultMessage: "Δ",
                                  }),
                                  align: "right",
                                  dataIndex: ["finances", "bonusRevenue"],
                                  render: (value, { rowSpan }) => ({
                                    children: <MoneyFormatter cents={value} />,
                                    props: { rowSpan },
                                  }),
                                },
                              ] as ColumnGroupType<TItemAggregatedByCurrency>["children"]
                            ).filter(Boolean),
                          },
                          {
                            title: intl.formatMessage({
                              defaultMessage: "Cash",
                            }),
                            align: "center",
                            children: (
                              [
                                !state.isCompactModeEnabled && {
                                  title: intl.formatMessage({
                                    defaultMessage: "In",
                                  }),
                                  align: "right",
                                  dataIndex: ["finances", "cashIn"],
                                  render: (value, { rowSpan }) => ({
                                    children: <MoneyFormatter cents={value} />,
                                    props: { rowSpan },
                                  }),
                                },
                                !state.isCompactModeEnabled && {
                                  title: intl.formatMessage({
                                    defaultMessage: "Out",
                                  }),
                                  align: "right",
                                  dataIndex: ["finances", "cashOut"],
                                  render: (value, { rowSpan }) => ({
                                    children: <MoneyFormatter cents={value} />,
                                    props: { rowSpan },
                                  }),
                                },
                                {
                                  title: intl.formatMessage({
                                    defaultMessage: "Δ",
                                  }),
                                  align: "right",
                                  dataIndex: ["finances", "cashRevenue"],
                                  render: (value, { rowSpan }) => ({
                                    children: <MoneyFormatter cents={value} />,
                                    props: { rowSpan },
                                  }),
                                },
                              ] as ColumnGroupType<TItemAggregatedByCurrency>["children"]
                            ).filter(Boolean),
                          },
                          !state.isCompactModeEnabled && {
                            title: intl.formatMessage({
                              defaultMessage: "Profit",
                            }),
                            align: "right",
                            dataIndex: ["finances", "profit"],
                            render: (value, { rowSpan }) => ({
                              children: <MoneyFormatter cents={value} />,
                              props: { rowSpan },
                            }),
                          },
                        ] as ColumnGroupType<TItemAggregatedByCurrency>["children"]
                      ).filter(Boolean),
                    },
                    {
                      title: intl.formatMessage({
                        defaultMessage: "Jackpots",
                      }),
                      align: "center",
                      dataIndex: ["jackpots", "paid"],
                      render: (value, { rowSpan }) => ({
                        children: (
                          <div style={{ textAlign: "right" }}>
                            <MoneyFormatter cents={value} />
                          </div>
                        ),
                        props: { rowSpan },
                      }),
                    },
                    {
                      title: intl.formatMessage({
                        defaultMessage: "Fortune Wheels",
                      }),
                      align: "center",
                      dataIndex: ["fortuneWheels", "paid"],
                      render: (value, { rowSpan }) => ({
                        children: (
                          <div style={{ textAlign: "right" }}>
                            <MoneyFormatter cents={value} />
                          </div>
                        ),
                        props: { rowSpan },
                      }),
                    },
                    {
                      title: intl.formatMessage({
                        defaultMessage: "Net profit",
                      }),
                      align: "right",
                      render: (item: TSummaryReportAggregatedItem) => {
                        if (item.games.length) {
                          return (
                            <MoneyFormatter cents={item.games[0].netProfit} />
                          );
                        }
                        return "—";
                      },
                    },
                  ]}
                />
                <Pagination
                  query={state.aggregateByCurrencyQuery}
                  onChange={() => {
                    state.aggregateByCurrency({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={intl.formatMessage({ defaultMessage: "Shops" })}
                key="halls"
              >
                <Table
                  dataSource={state.itemsAggregatedByHall}
                  showHeader={!!state.itemsAggregatedByHall.length}
                  rowKey={(item) => state.itemsAggregatedByHall.indexOf(item)}
                  size="small"
                  bordered
                  pagination={false}
                  columns={[
                    {
                      title: intl.formatMessage({
                        defaultMessage: "Currency",
                      }),
                      dataIndex: "currency",
                      align: "right",
                      render: (children, { rowSpan }) => ({
                        children,
                        props: { rowSpan },
                      }),
                    },
                    {
                      title: intl.formatMessage({
                        defaultMessage: "Agent",
                      }),
                      dataIndex: "agentName",
                      align: "left",
                      render: (value, { rowSpan }) => ({
                        children: value ?? "—",
                        props: { rowSpan },
                      }),
                    },
                    {
                      title: intl.formatMessage({
                        defaultMessage: "Shop",
                      }),
                      dataIndex: "hallName",
                      align: "left",
                      render: (value, { rowSpan }) => ({
                        children: value ?? "—",
                        props: { rowSpan },
                      }),
                    },
                    {
                      title: intl.formatMessage({
                        defaultMessage: "Games",
                      }),
                      align: "center",
                      children: (
                        [
                          {
                            title: intl.formatMessage({
                              defaultMessage: "Provider",
                            }),
                            align: "left",
                            render: (item: TSummaryReportAggregatedItem) => {
                              if (item.games[0]?.providerName === "warning") {
                                return {
                                  children: (
                                    <Alert
                                      style={{ margin: -8 }}
                                      showIcon
                                      type="warning"
                                      message={intl.formatMessage({
                                        defaultMessage:
                                          "Bonus revenue is not taken into this Shop. Please contact your manager for details.",
                                      })}
                                    />
                                  ),
                                  props: {
                                    colSpan: state.isCompactModeEnabled
                                      ? 8
                                      : 17,
                                  },
                                };
                              }

                              if (item.games.length) {
                                return item.games[0].providerName;
                              }

                              return {
                                children: <Row justify="center">—</Row>,
                                props: {
                                  colSpan: state.isCompactModeEnabled ? 2 : 4,
                                },
                              };
                            },
                          },
                          !state.isCompactModeEnabled && {
                            title: intl.formatMessage({
                              defaultMessage: "Bet",
                            }),
                            align: "right",
                            render: (item: TSummaryReportAggregatedItem) => {
                              if (
                                item.games.length &&
                                item.games[0].providerName !== "warning"
                              ) {
                                return (
                                  <MoneyFormatter cents={item.games[0].bet} />
                                );
                              }

                              return { props: { colSpan: 0 } };
                            },
                          },
                          !state.isCompactModeEnabled && {
                            title: intl.formatMessage({
                              defaultMessage: "Win",
                            }),
                            align: "right",
                            render: (item: TSummaryReportAggregatedItem) => {
                              if (
                                item.games.length &&
                                item.games[0].providerName !== "warning"
                              ) {
                                return (
                                  <MoneyFormatter cents={item.games[0].win} />
                                );
                              }
                              return { props: { colSpan: 0 } };
                            },
                          },
                          {
                            title: intl.formatMessage({
                              defaultMessage: "Profit",
                            }),
                            align: "right",
                            render: (item: TSummaryReportAggregatedItem) => {
                              if (
                                item.games.length &&
                                item.games[0].providerName !== "warning"
                              ) {
                                return (
                                  <MoneyFormatter
                                    cents={item.games[0].profit}
                                  />
                                );
                              }
                              return { props: { colSpan: 0 } };
                            },
                          },
                        ] as ColumnGroupType<TItemAggregatedByHall>["children"]
                      ).filter(Boolean),
                    },
                    {
                      title: intl.formatMessage({
                        defaultMessage: "Finance",
                      }),
                      align: "center",
                      children: (
                        [
                          {
                            title: intl.formatMessage({
                              defaultMessage: "Deposit",
                            }),
                            align: "center",
                            children: (
                              [
                                !state.isCompactModeEnabled && {
                                  title: intl.formatMessage({
                                    defaultMessage: "In",
                                  }),
                                  align: "right",
                                  dataIndex: ["finances", "balanceIn"],
                                  render: (
                                    value,
                                    { hasBonusWarning, games, rowSpan },
                                  ) => {
                                    if (games[0].providerName === "warning") {
                                      return { props: { colSpan: 0 } };
                                    }

                                    return {
                                      children: (
                                        <MoneyFormatter cents={value} />
                                      ),
                                      props: {
                                        colSpan: rowSpan <= 0 ? 0 : 1,
                                        rowSpan: hasBonusWarning
                                          ? rowSpan - 1
                                          : rowSpan,
                                      },
                                    };
                                  },
                                },
                                !state.isCompactModeEnabled && {
                                  title: intl.formatMessage({
                                    defaultMessage: "Out",
                                  }),
                                  align: "right",
                                  dataIndex: ["finances", "balanceOut"],
                                  render: (
                                    value,
                                    { games, rowSpan, hasBonusWarning },
                                  ) => {
                                    if (games[0].providerName === "warning") {
                                      return { props: { colSpan: 0 } };
                                    }
                                    return {
                                      children: (
                                        <MoneyFormatter cents={value} />
                                      ),
                                      props: {
                                        colSpan: rowSpan <= 0 ? 0 : 1,
                                        rowSpan: hasBonusWarning
                                          ? rowSpan - 1
                                          : rowSpan,
                                      },
                                    };
                                  },
                                },
                                {
                                  title: intl.formatMessage({
                                    defaultMessage: "Δ",
                                  }),
                                  align: "right",
                                  dataIndex: ["finances", "balanceRevenue"],
                                  render: (
                                    value,
                                    { games, rowSpan, hasBonusWarning },
                                  ) => {
                                    if (games[0].providerName === "warning") {
                                      return { props: { colSpan: 0 } };
                                    }

                                    return {
                                      children: (
                                        <MoneyFormatter cents={value} />
                                      ),
                                      props: {
                                        colSpan: rowSpan <= 0 ? 0 : 1,
                                        rowSpan: hasBonusWarning
                                          ? rowSpan - 1
                                          : rowSpan,
                                      },
                                    };
                                  },
                                },
                              ] as ColumnGroupType<TItemAggregatedByHall>["children"]
                            ).filter(Boolean),
                          },
                          {
                            title: intl.formatMessage({
                              defaultMessage: "Bonus",
                            }),
                            align: "center",
                            children: (
                              [
                                !state.isCompactModeEnabled && {
                                  title: intl.formatMessage({
                                    defaultMessage: "In",
                                  }),
                                  align: "right",
                                  dataIndex: ["finances", "bonusIn"],
                                  render: (
                                    value,
                                    { games, rowSpan, hasBonusWarning },
                                  ) => {
                                    if (games[0].providerName === "warning") {
                                      return { props: { colSpan: 0 } };
                                    }
                                    return {
                                      children: (
                                        <MoneyFormatter cents={value} />
                                      ),
                                      props: {
                                        colSpan: rowSpan <= 0 ? 0 : 1,
                                        rowSpan: hasBonusWarning
                                          ? rowSpan - 1
                                          : rowSpan,
                                      },
                                    };
                                  },
                                },
                                !state.isCompactModeEnabled && {
                                  title: intl.formatMessage({
                                    defaultMessage: "Out",
                                  }),
                                  align: "right",
                                  dataIndex: ["finances", "bonusOut"],
                                  render: (
                                    value,
                                    { games, rowSpan, hasBonusWarning },
                                  ) => {
                                    if (games[0].providerName === "warning") {
                                      return { props: { colSpan: 0 } };
                                    }
                                    return {
                                      children: (
                                        <MoneyFormatter cents={value} />
                                      ),
                                      props: {
                                        colSpan: rowSpan <= 0 ? 0 : 1,
                                        rowSpan: hasBonusWarning
                                          ? rowSpan - 1
                                          : rowSpan,
                                      },
                                    };
                                  },
                                },
                                {
                                  title: intl.formatMessage({
                                    defaultMessage: "Δ",
                                  }),
                                  align: "right",
                                  dataIndex: ["finances", "bonusRevenue"],
                                  render: (
                                    value,
                                    { games, rowSpan, hasBonusWarning },
                                  ) => {
                                    if (games[0].providerName === "warning") {
                                      return { props: { colSpan: 0 } };
                                    }
                                    return {
                                      children: (
                                        <MoneyFormatter cents={value} />
                                      ),
                                      props: {
                                        colSpan: rowSpan <= 0 ? 0 : 1,
                                        rowSpan: hasBonusWarning
                                          ? rowSpan - 1
                                          : rowSpan,
                                      },
                                    };
                                  },
                                },
                              ] as ColumnGroupType<TItemAggregatedByHall>["children"]
                            ).filter(Boolean),
                          },
                          {
                            title: intl.formatMessage({
                              defaultMessage: "Cash",
                            }),
                            align: "center",
                            children: (
                              [
                                !state.isCompactModeEnabled && {
                                  title: intl.formatMessage({
                                    defaultMessage: "In",
                                  }),
                                  align: "right",
                                  dataIndex: ["finances", "cashIn"],
                                  render: (
                                    value,
                                    { games, rowSpan, hasBonusWarning },
                                  ) => {
                                    if (games[0].providerName === "warning") {
                                      return { props: { colSpan: 0 } };
                                    }
                                    return {
                                      children: (
                                        <MoneyFormatter cents={value} />
                                      ),
                                      props: {
                                        colSpan: rowSpan <= 0 ? 0 : 1,
                                        rowSpan: hasBonusWarning
                                          ? rowSpan - 1
                                          : rowSpan,
                                      },
                                    };
                                  },
                                },
                                !state.isCompactModeEnabled && {
                                  title: intl.formatMessage({
                                    defaultMessage: "Out",
                                  }),
                                  align: "right",
                                  dataIndex: ["finances", "cashOut"],
                                  render: (
                                    value,
                                    { games, rowSpan, hasBonusWarning },
                                  ) => {
                                    if (games[0].providerName === "warning") {
                                      return { props: { colSpan: 0 } };
                                    }
                                    return {
                                      children: (
                                        <MoneyFormatter cents={value} />
                                      ),
                                      props: {
                                        colSpan: rowSpan <= 0 ? 0 : 1,
                                        rowSpan: hasBonusWarning
                                          ? rowSpan - 1
                                          : rowSpan,
                                      },
                                    };
                                  },
                                },
                                {
                                  title: intl.formatMessage({
                                    defaultMessage: "Δ",
                                  }),
                                  align: "right",
                                  dataIndex: ["finances", "cashRevenue"],
                                  render: (
                                    value,
                                    { games, rowSpan, hasBonusWarning },
                                  ) => {
                                    if (games[0].providerName === "warning") {
                                      return { props: { colSpan: 0 } };
                                    }
                                    return {
                                      children: (
                                        <MoneyFormatter cents={value} />
                                      ),
                                      props: {
                                        colSpan: rowSpan <= 0 ? 0 : 1,
                                        rowSpan: hasBonusWarning
                                          ? rowSpan - 1
                                          : rowSpan,
                                      },
                                    };
                                  },
                                },
                              ] as ColumnGroupType<TItemAggregatedByHall>["children"]
                            ).filter(Boolean),
                          },
                          !state.isCompactModeEnabled && {
                            title: intl.formatMessage({
                              defaultMessage: "Profit",
                            }),
                            align: "right",
                            dataIndex: ["finances", "profit"],
                            render: (
                              value,
                              { games, rowSpan, hasBonusWarning },
                            ) => {
                              if (games[0].providerName === "warning") {
                                return { props: { colSpan: 0 } };
                              }
                              return {
                                children: <MoneyFormatter cents={value} />,
                                props: {
                                  colSpan: rowSpan <= 0 ? 0 : 1,
                                  rowSpan: hasBonusWarning
                                    ? rowSpan - 1
                                    : rowSpan,
                                },
                              };
                            },
                          },
                        ] as ColumnGroupType<TItemAggregatedByHall>["children"]
                      ).filter(Boolean),
                    },
                    {
                      title: intl.formatMessage({
                        defaultMessage: "Jackpots",
                      }),
                      align: "center",
                      dataIndex: ["jackpots", "paid"],
                      render: (value, { games, rowSpan, hasBonusWarning }) => {
                        if (games[0].providerName === "warning") {
                          return { props: { colSpan: 0 } };
                        }
                        return {
                          children: (
                            <div style={{ textAlign: "right" }}>
                              <MoneyFormatter cents={value} />
                            </div>
                          ),
                          props: {
                            colSpan: rowSpan <= 0 ? 0 : 1,
                            rowSpan: hasBonusWarning ? rowSpan - 1 : rowSpan,
                          },
                        };
                      },
                    },
                    {
                      title: intl.formatMessage({
                        defaultMessage: "Fortune Wheels",
                      }),
                      align: "center",
                      dataIndex: ["fortuneWheels", "paid"],
                      render: (value, { games, rowSpan, hasBonusWarning }) => {
                        if (games[0].providerName === "warning") {
                          return { props: { colSpan: 0 } };
                        }
                        return {
                          children: (
                            <div style={{ textAlign: "right" }}>
                              <MoneyFormatter cents={value} />
                            </div>
                          ),
                          props: {
                            colSpan: rowSpan <= 0 ? 0 : 1,
                            rowSpan: hasBonusWarning ? rowSpan - 1 : rowSpan,
                          },
                        };
                      },
                    },
                    {
                      title: intl.formatMessage({
                        defaultMessage: "Net profit",
                      }),
                      align: "right",
                      render: (item: TSummaryReportAggregatedItem) => {
                        if (item.games[0]?.providerName === "warning") {
                          return { props: { colSpan: 0 } };
                        }

                        if (item.games.length) {
                          return (
                            <MoneyFormatter cents={item.games[0].netProfit} />
                          );
                        }
                        return "—";
                      },
                    },
                  ]}
                />
                <Pagination
                  query={state.aggregateByHallQuery}
                  onChange={() => {
                    state.aggregateByHall({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
            </Tabs>
          </Space>
        </Spin>
      </Card>
    </Page>
  );
}

export default observer(SummaryReport);
