import { Button, Card, Col, Row, Space, Spin, Switch, Table, Tabs } from "antd";
import classNames from "classnames";
import { snakeCase } from "lodash-es";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { TFinancialReportAggregatedItem, TFinancialReportItem } from "@/api";
import { useViewModel } from "@/hooks/use-view-model";
import { useGlobalStore } from "@/stores";
import CurrencySelector from "@/ui/_common_/currency-selector";
import DateRangePicker from "@/ui/_common_/date-range-picker";
import ErrorsFormatter from "@/ui/_common_/errors-formatter";
import MoneyFormatter from "@/ui/_common_/money-formatter";
import Page from "@/ui/_common_/page";
import Pagination from "@/ui/_common_/pagination";
import Selector from "@/ui/_common_/selector";

import { FinancialReportState } from "./FinancialReportState";

function BonusReport() {
  const globalStore = useGlobalStore();
  const state = useViewModel(() => new FinancialReportState(globalStore), {});
  const intl = useIntl();
  const { permissionsStore } = globalStore;
  const { formatMessage: _ } = intl;

  return (
    <Page title={intl.formatMessage({ defaultMessage: "Financial report" })}>
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {permissionsStore.has("SelectClient") && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <Selector
                store={state.clientSelectorStore}
                placeholder={intl.formatMessage({
                  defaultMessage: "Select client",
                })}
              />
            </Col>
          )}
          {!!state.clientSelectorStore.selectedId && (
            <>
              {permissionsStore.has("SelectAgent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.agentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select an agent",
                    })}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectSubagent") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.subagentSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select subagent",
                    })}
                  />
                </Col>
              )}
              {permissionsStore.has("SelectHall") && (
                <Col xs={24} md={8} lg={6} xl={4}>
                  <Selector
                    store={state.hallSelectorStore}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Select shop",
                    })}
                  />
                </Col>
              )}
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={state.playerSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select player",
                  })}
                />
              </Col>
              <Col xs={24} md={8} lg={6} xl={4}>
                <Selector
                  store={state.kioskSelectorStore}
                  placeholder={intl.formatMessage({
                    defaultMessage: "Select kiosk",
                  })}
                />
              </Col>
            </>
          )}
          <Col xs={24} md={8} lg={6} xl={4} style={{ marginLeft: "auto" }}>
            <Row
              gutter={[8, 8]}
              justify="end"
              align="middle"
              style={{ height: "100%" }}
            >
              <Col>{intl.formatMessage({ defaultMessage: "Compact" })}</Col>
              <Col>
                <Switch
                  checked={state.isCompactModeEnabled}
                  onChange={(value) => (state.isCompactModeEnabled = value)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Row gutter={[12, 12]} wrap={true}>
          {!state.userStore.hall && (
            <Col xs={24} md={8} lg={6} xl={4}>
              <CurrencySelector
                value={state.currency}
                onChange={state.setCurrency}
                style={{ width: "100%" }}
              />
            </Col>
          )}
          <Col>
            <DateRangePicker store={state} />
          </Col>
          <Col xs={12} md={6} lg={6} xl={3} style={{ marginLeft: "auto" }}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              disabled={!state.clientSelectorStore.selectedId}
              onClick={state.apply}
            >
              {intl.formatMessage({ defaultMessage: "Apply" })}
            </Button>
          </Col>
        </Row>
      </Card>
      <Card size="small">
        <Spin
          spinning={
            state.filterQuery.isPending ||
            state.aggregateByCurrencyQuery.isPending ||
            state.aggregateByPlayerQuery.isPending ||
            state.aggregateByKioskQuery.isPending ||
            state.aggregateByUserQuery.isPending ||
            state.aggregateByHallQuery.isPending
          }
        >
          <Space direction="vertical">
            <ErrorsFormatter
              queries={[
                state.filterQuery,
                state.aggregateByCurrencyQuery,
                state.aggregateByPlayerQuery,
                state.aggregateByKioskQuery,
                state.aggregateByUserQuery,
                state.aggregateByHallQuery,
              ]}
            />
            <Tabs
              destroyInactiveTabPane={true}
              activeKey={state.tab}
              onChange={(value) => (state.tab = value)}
            >
              <Tabs.TabPane
                tab={intl.formatMessage({ defaultMessage: "Detailed" })}
                key="detailed"
              >
                <Table
                  dataSource={state.filterQuery.items}
                  showHeader={!!state.filterQuery.items.length}
                  rowKey="id"
                  size="small"
                  bordered
                  pagination={false}
                  components={{
                    body: {
                      row: (props: any) => {
                        const item = props.children?.[0]?.props?.record;
                        const type = snakeCase(item?.type);
                        return (
                          <tr
                            {...props}
                            className={classNames(
                              props.className,
                              type === "out_reserved"
                                ? "ant-table-row-color-gray"
                                : "",
                            )}
                          />
                        );
                      },
                    },
                  }}
                >
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "ID",
                    })}
                    dataIndex="id"
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Date/time" })}
                    dataIndex="createdAt"
                    render={(_, item: TFinancialReportItem) =>
                      new Date(item.createdAt).toLocaleString()
                    }
                  />
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Agent",
                    })}
                    dataIndex="agentName"
                    render={(agentName) => agentName ?? "—"}
                  />
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Shop",
                    })}
                    dataIndex="hallName"
                    render={(hallName) => hallName ?? "—"}
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "User" })}
                    dataIndex="userName"
                    render={(userName) => userName ?? "—"}
                  />
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Player",
                    })}
                    dataIndex="playerName"
                    render={(playerName) => playerName ?? "—"}
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Kiosk" })}
                    dataIndex="kioskName"
                    render={(kioskName) => kioskName ?? "—"}
                  />
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Transaction type",
                    })}
                    dataIndex="type"
                    render={(operationType) =>
                      _({
                        id: `reports/financial/operation_type/${snakeCase(
                          operationType,
                        )}`,
                        defaultMessage: operationType,
                      })
                    }
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "Amount",
                    })}
                    render={(_, item: TFinancialReportItem) => (
                      <MoneyFormatter cents={item.amount} />
                    )}
                  />
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Currency",
                    })}
                    dataIndex="currency"
                    align="right"
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "Balance after",
                    })}
                    dataIndex="balanceAfter"
                    render={(_, item: TFinancialReportItem) => (
                      <MoneyFormatter cents={item.balanceAfter} />
                    )}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "Bonus balance after",
                    })}
                    dataIndex="bonusBalanceAfter"
                    render={(_, item: TFinancialReportItem) => (
                      <MoneyFormatter cents={item.bonusBalanceAfter} />
                    )}
                  />
                </Table>
                <Pagination
                  query={state.filterQuery}
                  onChange={() => {
                    state.filter({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={intl.formatMessage({
                  defaultMessage: "Currency",
                })}
                key="currency"
              >
                <Table
                  dataSource={state.aggregateByCurrencyQuery.items}
                  showHeader={!!state.aggregateByCurrencyQuery.items.length}
                  rowKey={(item) =>
                    state.aggregateByCurrencyQuery.items.indexOf(item)
                  }
                  size="small"
                  bordered
                  pagination={false}
                >
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Currency",
                    })}
                    dataIndex="currency"
                    align="right"
                  />
                  {state.isCompactModeEnabled ? (
                    <>
                      <Table.Column
                        align="right"
                        title={intl.formatMessage({ defaultMessage: "In" })}
                        render={(_, item: TFinancialReportAggregatedItem) => {
                          if (!("cashIn" in item)) {
                            return "—";
                          }
                          return (
                            <MoneyFormatter
                              cents={item.cashIn + item.balanceIn}
                            />
                          );
                        }}
                      />
                      <Table.Column
                        align="right"
                        title={intl.formatMessage({ defaultMessage: "Out" })}
                        render={(_, item: TFinancialReportAggregatedItem) => {
                          if (!("cashOut" in item)) {
                            return "—";
                          }
                          return (
                            <MoneyFormatter
                              cents={item.cashOut + item.balanceOut}
                            />
                          );
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Table.ColumnGroup
                        title={intl.formatMessage({ defaultMessage: "Cash" })}
                      >
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({ defaultMessage: "In" })}
                          dataIndex="cashIn"
                          render={(cashIn: number) => (
                            <MoneyFormatter cents={cashIn} />
                          )}
                        />
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({ defaultMessage: "Out" })}
                          dataIndex="cashOut"
                          render={(cashOut: number) => (
                            <MoneyFormatter cents={cashOut} />
                          )}
                        />
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({
                            defaultMessage: "Revenue",
                          })}
                          dataIndex="cashRevenue"
                          render={(cashRevenue: number) => (
                            <MoneyFormatter cents={cashRevenue} />
                          )}
                        />
                      </Table.ColumnGroup>
                      <Table.ColumnGroup
                        title={intl.formatMessage({
                          defaultMessage: "Balance",
                        })}
                      >
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({ defaultMessage: "In" })}
                          dataIndex="balanceIn"
                          render={(balanceIn: number) => (
                            <MoneyFormatter cents={balanceIn} />
                          )}
                        />
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({ defaultMessage: "Out" })}
                          dataIndex="balanceOut"
                          render={(balanceOut: number) => (
                            <MoneyFormatter cents={balanceOut} />
                          )}
                        />
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({
                            defaultMessage: "Revenue",
                          })}
                          dataIndex="balanceRevenue"
                          render={(balanceRevenue: number) => (
                            <MoneyFormatter cents={balanceRevenue} />
                          )}
                        />
                      </Table.ColumnGroup>
                      <Table.ColumnGroup
                        title={intl.formatMessage({ defaultMessage: "Bonus" })}
                      >
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({ defaultMessage: "In" })}
                          dataIndex="bonusIn"
                          render={(bonusIn: number) => (
                            <MoneyFormatter cents={bonusIn} />
                          )}
                        />
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({ defaultMessage: "Out" })}
                          dataIndex="bonusOut"
                          render={(bonusOut: number) => (
                            <MoneyFormatter cents={bonusOut} />
                          )}
                        />
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({
                            defaultMessage: "Revenue",
                          })}
                          dataIndex="bonusRevenue"
                          render={(bonusRevenue: number) => (
                            <MoneyFormatter cents={bonusRevenue} />
                          )}
                        />
                      </Table.ColumnGroup>
                    </>
                  )}
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "Redeem voucher",
                    })}
                    dataIndex="vouchersRedeemed"
                    render={(value: number) => <MoneyFormatter cents={value} />}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "Total revenue",
                    })}
                    dataIndex="totalRevenue"
                    render={(totalRevenue: number) => (
                      <MoneyFormatter cents={totalRevenue} />
                    )}
                  />
                </Table>
                <Pagination
                  query={state.aggregateByCurrencyQuery}
                  onChange={() => {
                    state.aggregateByCurrency({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={intl.formatMessage({ defaultMessage: "Shops" })}
                key="halls"
              >
                <Table
                  dataSource={state.aggregateByHallQuery.items}
                  showHeader={!!state.aggregateByHallQuery.items.length}
                  rowKey={(item) =>
                    state.aggregateByHallQuery.items.indexOf(item)
                  }
                  size="small"
                  bordered
                  pagination={false}
                >
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Currency",
                    })}
                    dataIndex="currency"
                    align="right"
                  />
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Shop",
                    })}
                    dataIndex="hallName"
                    render={(hallName) => hallName ?? "—"}
                  />
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Agent",
                    })}
                    dataIndex="agentName"
                    render={(agentName) => agentName ?? "—"}
                  />
                  {state.isCompactModeEnabled ? (
                    <>
                      <Table.Column
                        align="right"
                        title={intl.formatMessage({ defaultMessage: "In" })}
                        render={(_, item: TFinancialReportAggregatedItem) => {
                          if (!("cashIn" in item)) {
                            return "—";
                          }
                          return (
                            <MoneyFormatter
                              cents={item.cashIn + item.balanceIn}
                            />
                          );
                        }}
                      />
                      <Table.Column
                        align="right"
                        title={intl.formatMessage({ defaultMessage: "Out" })}
                        render={(_, item: TFinancialReportAggregatedItem) => {
                          if (!("cashOut" in item)) {
                            return "—";
                          }
                          return (
                            <MoneyFormatter
                              cents={item.cashOut + item.balanceOut}
                            />
                          );
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Table.ColumnGroup
                        title={intl.formatMessage({ defaultMessage: "Cash" })}
                      >
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({ defaultMessage: "In" })}
                          dataIndex="cashIn"
                          render={(cashIn: number) => (
                            <MoneyFormatter cents={cashIn} />
                          )}
                        />
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({ defaultMessage: "Out" })}
                          dataIndex="cashOut"
                          render={(cashOut: number) => (
                            <MoneyFormatter cents={cashOut} />
                          )}
                        />
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({
                            defaultMessage: "Revenue",
                          })}
                          dataIndex="cashRevenue"
                          render={(cashRevenue: number) => (
                            <MoneyFormatter cents={cashRevenue} />
                          )}
                        />
                      </Table.ColumnGroup>
                      <Table.ColumnGroup
                        title={intl.formatMessage({
                          defaultMessage: "Balance",
                        })}
                      >
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({ defaultMessage: "In" })}
                          dataIndex="balanceIn"
                          render={(balanceIn: number) => (
                            <MoneyFormatter cents={balanceIn} />
                          )}
                        />
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({ defaultMessage: "Out" })}
                          dataIndex="balanceOut"
                          render={(balanceOut: number) => (
                            <MoneyFormatter cents={balanceOut} />
                          )}
                        />
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({
                            defaultMessage: "Revenue",
                          })}
                          dataIndex="balanceRevenue"
                          render={(balanceRevenue: number) => (
                            <MoneyFormatter cents={balanceRevenue} />
                          )}
                        />
                      </Table.ColumnGroup>
                      <Table.ColumnGroup
                        title={intl.formatMessage({ defaultMessage: "Bonus" })}
                      >
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({ defaultMessage: "In" })}
                          dataIndex="bonusIn"
                          render={(bonusIn: number) => (
                            <MoneyFormatter cents={bonusIn} />
                          )}
                        />
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({ defaultMessage: "Out" })}
                          dataIndex="bonusOut"
                          render={(bonusOut: number) => (
                            <MoneyFormatter cents={bonusOut} />
                          )}
                        />
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({
                            defaultMessage: "Revenue",
                          })}
                          dataIndex="bonusRevenue"
                          render={(bonusRevenue: number) => (
                            <MoneyFormatter cents={bonusRevenue} />
                          )}
                        />
                      </Table.ColumnGroup>
                    </>
                  )}
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "Redeem voucher",
                    })}
                    dataIndex="vouchersRedeemed"
                    render={(value: number) => <MoneyFormatter cents={value} />}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "Total revenue",
                    })}
                    dataIndex="totalRevenue"
                    render={(totalRevenue: number) => (
                      <MoneyFormatter cents={totalRevenue} />
                    )}
                  />
                </Table>
                <Pagination
                  query={state.aggregateByHallQuery}
                  onChange={() => {
                    state.aggregateByHall({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={intl.formatMessage({ defaultMessage: "Players" })}
                key="players"
              >
                <Table
                  dataSource={state.aggregateByPlayerQuery.items}
                  showHeader={!!state.aggregateByPlayerQuery.items.length}
                  rowKey={(item) =>
                    state.aggregateByPlayerQuery.items.indexOf(item)
                  }
                  size="small"
                  bordered
                  pagination={false}
                >
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Currency",
                    })}
                    dataIndex="currency"
                    align="right"
                  />
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Player",
                    })}
                    dataIndex="playerName"
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Balance in" })}
                    dataIndex="balanceIn"
                    render={(balanceIn: number) => (
                      <MoneyFormatter cents={balanceIn} />
                    )}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "Balance out",
                    })}
                    dataIndex="balanceOut"
                    render={(balanceOut: number) => (
                      <MoneyFormatter cents={balanceOut} />
                    )}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "Balance revenue",
                    })}
                    dataIndex="balanceRevenue"
                    render={(balanceRevenue: number) => (
                      <MoneyFormatter cents={balanceRevenue} />
                    )}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Bonus in" })}
                    dataIndex="bonusIn"
                    render={(bonusIn: number) => (
                      <MoneyFormatter cents={bonusIn} />
                    )}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Bonus out" })}
                    dataIndex="bonusOut"
                    render={(bonusOut: number) => (
                      <MoneyFormatter cents={bonusOut} />
                    )}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "Bonus revenue",
                    })}
                    dataIndex="bonusRevenue"
                    render={(bonusRevenue: number) => (
                      <MoneyFormatter cents={bonusRevenue} />
                    )}
                  />
                </Table>
                <Pagination
                  query={state.aggregateByPlayerQuery}
                  onChange={() => {
                    state.aggregateByPlayer({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={intl.formatMessage({ defaultMessage: "Kiosks" })}
                key="kiosks"
              >
                <Table
                  dataSource={state.aggregateByKioskQuery.items}
                  showHeader={!!state.aggregateByKioskQuery.items.length}
                  rowKey={(item) =>
                    state.aggregateByKioskQuery.items.indexOf(item)
                  }
                  size="small"
                  bordered
                  pagination={false}
                >
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Currency",
                    })}
                    dataIndex="currency"
                    align="right"
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "Kiosk" })}
                    dataIndex="kioskName"
                    render={(kioskName) => kioskName ?? "—"}
                    width="100%"
                  />
                  {state.isCompactModeEnabled ? (
                    <>
                      <Table.Column
                        align="right"
                        title={intl.formatMessage({ defaultMessage: "In" })}
                        render={(_, item: TFinancialReportAggregatedItem) => {
                          if (!("kioskId" in item)) {
                            return "—";
                          }
                          return (
                            <MoneyFormatter
                              cents={
                                item.cashIn + item.balanceIn + item.vouchersIn
                              }
                            />
                          );
                        }}
                      />
                      <Table.Column
                        align="right"
                        title={intl.formatMessage({ defaultMessage: "Out" })}
                        render={(_, item: TFinancialReportAggregatedItem) => {
                          if (!("kioskId" in item)) {
                            return "—";
                          }
                          return (
                            <MoneyFormatter
                              cents={
                                item.cashOut +
                                item.balanceOut +
                                item.vouchersOut
                              }
                            />
                          );
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <Table.ColumnGroup
                        title={intl.formatMessage({ defaultMessage: "Cash" })}
                      >
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({ defaultMessage: "In" })}
                          dataIndex="cashIn"
                          render={(cashIn: number) => (
                            <MoneyFormatter cents={cashIn} />
                          )}
                        />
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({ defaultMessage: "Out" })}
                          dataIndex="cashOut"
                          render={(cashOut: number) => (
                            <MoneyFormatter cents={cashOut} />
                          )}
                        />
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({
                            defaultMessage: "Revenue",
                          })}
                          dataIndex="cashRevenue"
                          render={(cashRevenue: number) => (
                            <MoneyFormatter cents={cashRevenue} />
                          )}
                        />
                      </Table.ColumnGroup>
                      <Table.ColumnGroup
                        title={intl.formatMessage({
                          defaultMessage: "Balance",
                        })}
                      >
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({ defaultMessage: "In" })}
                          dataIndex="balanceIn"
                          render={(balanceIn: number) => (
                            <MoneyFormatter cents={balanceIn} />
                          )}
                        />
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({ defaultMessage: "Out" })}
                          dataIndex="balanceOut"
                          render={(balanceOut: number) => (
                            <MoneyFormatter cents={balanceOut} />
                          )}
                        />
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({
                            defaultMessage: "Revenue",
                          })}
                          dataIndex="balanceRevenue"
                          render={(balanceRevenue: number) => (
                            <MoneyFormatter cents={balanceRevenue} />
                          )}
                        />
                      </Table.ColumnGroup>
                      <Table.ColumnGroup
                        title={intl.formatMessage({ defaultMessage: "Bonus" })}
                      >
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({ defaultMessage: "In" })}
                          dataIndex="bonusIn"
                          render={(bonusIn: number) => (
                            <MoneyFormatter cents={bonusIn} />
                          )}
                        />
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({ defaultMessage: "Out" })}
                          dataIndex="bonusOut"
                          render={(bonusOut: number) => (
                            <MoneyFormatter cents={bonusOut} />
                          )}
                        />
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({
                            defaultMessage: "Revenue",
                          })}
                          dataIndex="bonusRevenue"
                          render={(bonusRevenue: number) => (
                            <MoneyFormatter cents={bonusRevenue} />
                          )}
                        />
                      </Table.ColumnGroup>
                      <Table.ColumnGroup
                        title={intl.formatMessage({
                          defaultMessage: "Vouchers",
                        })}
                      >
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({ defaultMessage: "In" })}
                          dataIndex="vouchersIn"
                          render={(value: number) => (
                            <MoneyFormatter cents={value} />
                          )}
                        />
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({ defaultMessage: "Out" })}
                          dataIndex="vouchersOut"
                          render={(value: number) => (
                            <MoneyFormatter cents={value} />
                          )}
                        />
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({
                            defaultMessage: "Redeemed",
                          })}
                          dataIndex="vouchersRedeemed"
                          render={(value: number) => (
                            <MoneyFormatter cents={value} />
                          )}
                        />
                        <Table.Column
                          align="right"
                          title={intl.formatMessage({
                            defaultMessage: "Not redeemed",
                          })}
                          dataIndex="vouchersNotRedeemed"
                          render={(value: number) => (
                            <MoneyFormatter cents={value} />
                          )}
                        />
                      </Table.ColumnGroup>
                    </>
                  )}
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "Total revenue",
                    })}
                    dataIndex="totalRevenue"
                    render={(totalRevenue: number) => (
                      <MoneyFormatter cents={totalRevenue} />
                    )}
                  />
                </Table>
                <Pagination
                  query={state.aggregateByKioskQuery}
                  onChange={() => {
                    state.aggregateByKiosk({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={intl.formatMessage({ defaultMessage: "Users" })}
                key="users"
              >
                <Table
                  dataSource={state.aggregateByUserQuery.items}
                  showHeader={!!state.aggregateByUserQuery.items.length}
                  rowKey={(item) =>
                    state.aggregateByUserQuery.items.indexOf(item)
                  }
                  size="small"
                  bordered
                  pagination={false}
                >
                  <Table.Column
                    title={intl.formatMessage({
                      defaultMessage: "Currency",
                    })}
                    dataIndex="currency"
                    align="right"
                  />
                  <Table.Column
                    title={intl.formatMessage({ defaultMessage: "User" })}
                    dataIndex="userName"
                    render={(userName) => userName ?? "—"}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Balance in" })}
                    dataIndex="balanceIn"
                    render={(balanceIn: number) => (
                      <MoneyFormatter cents={balanceIn} />
                    )}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "Balance out",
                    })}
                    dataIndex="balanceOut"
                    render={(balanceOut: number) => (
                      <MoneyFormatter cents={balanceOut} />
                    )}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "Balance revenue",
                    })}
                    dataIndex="balanceRevenue"
                    render={(balanceRevenue: number) => (
                      <MoneyFormatter cents={balanceRevenue} />
                    )}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Bonus in" })}
                    dataIndex="bonusIn"
                    render={(bonusIn: number) => (
                      <MoneyFormatter cents={bonusIn} />
                    )}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({ defaultMessage: "Bonus out" })}
                    dataIndex="bonusOut"
                    render={(bonusOut: number) => (
                      <MoneyFormatter cents={bonusOut} />
                    )}
                  />
                  <Table.Column
                    align="right"
                    title={intl.formatMessage({
                      defaultMessage: "Bonus revenue",
                    })}
                    dataIndex="bonusRevenue"
                    render={(bonusRevenue: number) => (
                      <MoneyFormatter cents={bonusRevenue} />
                    )}
                  />
                </Table>
                <Pagination
                  query={state.aggregateByUserQuery}
                  onChange={() => {
                    state.aggregateByUser({ preservePageNumber: true });
                  }}
                />
              </Tabs.TabPane>
            </Tabs>
          </Space>
        </Spin>
      </Card>
    </Page>
  );
}

export default observer(BonusReport);
